import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_ag_radio_item = _resolveComponent("ag-radio-item")!
  const _component_ag_radio = _resolveComponent("ag-radio")!
  const _component_MFinancialProfileCombobox = _resolveComponent("MFinancialProfileCombobox")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_a_g_text_field = _resolveComponent("a-g-text-field")!
  const _component_ag_checkbox = _resolveComponent("ag-checkbox")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_GenerateSOA = _resolveComponent("GenerateSOA")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_AgTable = _resolveComponent("AgTable")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_AgDiv = _resolveComponent("AgDiv")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      _ctx.$store.getters.isFetchingSectors ||
      _ctx.$store.getters.isFetchingFinancialProfiles ||
      _ctx.$store.getters.isFetchingOrganization
    )
      ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.showSelectByDropdown)
            ? (_openBlock(), _createBlock(_component_ag_card, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_radio, {
                    testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.RADIO_BUTTON, 'soa-selectBy'),
                    modelValue: _ctx.selectBy,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectBy) = $event)),
                    label: "Whether to show individual or group?",
                    inline: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_radio_item, {
                        name: "selectBy",
                        testId: 
            _ctx.genTestId(
              _ctx.ELEMENT_TYPES.RADIO_BUTTON,
              `soa-selectBy-${_ctx.selectByOpts.group.value}`
            )
          ,
                        value: _ctx.selectByOpts.group.value,
                        label: _ctx.selectByOpts.group.label
                      }, null, 8, ["testId", "value", "label"]),
                      _createVNode(_component_ag_radio_item, {
                        name: "selectBy",
                        testId: 
            _ctx.genTestId(
              _ctx.ELEMENT_TYPES.RADIO_BUTTON,
              `soa-selectBy-${_ctx.selectByOpts.individual.value}`
            )
          ,
                        value: _ctx.selectByOpts.individual.value,
                        label: _ctx.selectByOpts.individual.label
                      }, null, 8, ["testId", "value", "label"])
                    ]),
                    _: 1
                  }, 8, ["testId", "modelValue"]),
                  (_ctx.selectBy === _ctx.selectByOpts.individual.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_MFinancialProfileCombobox, {
                          class: "fp-combo",
                          inputValue: _ctx.financialProfilePublicId,
                          "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.financialProfilePublicId) = $event)),
                          label: "Financial Profiles",
                          itemValue: "value",
                          itemLabel: "label",
                          disabled: _ctx.isFinancialProfileFetching,
                          options: _ctx.financialProfileOptions
                        }, null, 8, ["inputValue", "disabled", "options"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.selectBy === _ctx.selectByOpts.group.value)
            ? (_openBlock(), _createBlock(_component_ag_card, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_radio, {
                    label: "Please select the city.",
                    modelValue: _ctx.selectedCity,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCity) = $event)),
                    inline: "",
                    testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.RADIO_BUTTON, 'soa-selectedCity')
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cities, (city, index) => {
                        return (_openBlock(), _createBlock(_component_ag_radio_item, {
                          name: "selectedCity",
                          key: index,
                          testId: 
            _ctx.genTestId(
              _ctx.ELEMENT_TYPES.RADIO_BUTTON,
              `soa-selectedCity-${city.toLowerCase()}`
            )
          ,
                          value: city.toLowerCase(),
                          label: city
                        }, null, 8, ["testId", "value", "label"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "testId"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.selectBy === _ctx.selectByOpts.group.value)
            ? (_openBlock(), _createBlock(_component_ag_card, { key: 2 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_g_text_field, {
                    label: "Search Profile",
                    modelValue: _ctx.searchProfile,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchProfile) = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_ag_column, { class: "agency-scroll" }, {
                    default: _withCtx(() => [
                      (!_ctx.searchProfile)
                        ? (_openBlock(), _createBlock(_component_ag_checkbox, {
                            key: 0,
                            testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.CHECK_BOX, `soa-profiles-selectAll`),
                            onClick: _ctx.onSelectAll,
                            label: "Select all",
                            modelValue: _ctx.checkSelectAllCheckBox,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.checkSelectAllCheckBox) = $event))
                          }, null, 8, ["testId", "onClick", "modelValue"]))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProfiles, (profile) => {
                        return (_openBlock(), _createBlock(_component_ag_checkbox, {
                          key: profile.public_id,
                          testId: 
            _ctx.genTestId(
              _ctx.ELEMENT_TYPES.CHECK_BOX,
              `soa-profiles-${profile.public_id}`
            )
          ,
                          modelValue: _ctx.selectedProfiles,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedProfiles) = $event)),
                          value: profile.public_id,
                          label: `${profile.financial_profile_name} - ${profile.platform_id}`
                        }, null, 8, ["testId", "modelValue", "value", "label"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_GenerateSOA, {
            onFetchSOA: _ctx.fetchSOA,
            disableActionBtns: !_ctx.isProfileSelected,
            onSendSOAEmail: _ctx.sendSOAEmail,
            disableSendBtn: !_ctx.isGroupSelected,
            onDownloadOldSOA: _ctx.downloadOldSOA
          }, null, 8, ["onFetchSOA", "disableActionBtns", "onSendSOAEmail", "disableSendBtn", "onDownloadOldSOA"])
        ])),
    _createVNode(_component_AgDiv, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_ag_heading, {
              variant: "h2",
              title: "Pending Receipt and Invoice"
            }),
            (_ctx.pendingPostingInvoices.length > 0)
              ? (_openBlock(), _createBlock(_component_ag_heading, {
                  key: 0,
                  variant: "h2",
                  title: "Invoices"
                }))
              : _createCommentVNode("", true),
            (_ctx.pendingPostingInvoices && _ctx.pendingPostingInvoices.length > 0)
              ? (_openBlock(), _createBlock(_component_AgTable, {
                  key: 1,
                  headers: _ctx.tableHeaders_Invoices,
                  items: _ctx.pendingPostingInvoices,
                  "has-pagination": false,
                  "items-per-page": 5,
                  "has-search": false
                }, {
                  "col-ticket_no": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.ticket_no), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  "col-pnr": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.pnr), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  "col-posting_status": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.posting_status), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["headers", "items"]))
              : _createCommentVNode("", true),
            (_ctx.pendingPostingReceipts.length > 0)
              ? (_openBlock(), _createBlock(_component_ag_heading, {
                  key: 2,
                  variant: "h2",
                  title: "Receipts",
                  style: {"margin-bottom":"0.5cm","margin-top":"0.5cm"}
                }))
              : _createCommentVNode("", true),
            (_ctx.pendingPostingReceipts && _ctx.pendingPostingReceipts.length > 0)
              ? (_openBlock(), _createBlock(_component_AgTable, {
                  key: 3,
                  headers: _ctx.tableHeaders_Receipts,
                  items: _ctx.pendingPostingReceipts,
                  "has-pagination": false,
                  "items-per-page": 5,
                  "has-search": false
                }, {
                  "col-payment_id": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.payment_id), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  "col-amount": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.amount), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  "col-posting_status": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.posting_status), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["headers", "items"]))
              : _createCommentVNode("", true),
            (
          _ctx.pendingPostingInvoices.length === 0 &&
          _ctx.pendingPostingReceipts.length === 0
        )
              ? (_openBlock(), _createBlock(_component_AgNotFound, {
                  key: 4,
                  "test-id": "",
                  heading: "No Pending Receipts & Invoices Found",
                  description: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}