import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MCombobox = _resolveComponent("MCombobox")!

  return (_openBlock(), _createBlock(_component_MCombobox, {
    inputValue: _ctx.searchValue,
    "onUpdate:inputValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
      _ctx.onUpdateModelValue
    ],
    label: "Select Organization",
    itemValue: "value",
    itemLabel: "label",
    options: _ctx.filteredOrgs,
    disabled: _ctx.isFetchingOrganizations
  }, null, 8, ["inputValue", "options", "onUpdate:inputValue", "disabled"]))
}