<template>
  <ag-card>
    <ag-heading variant="h2" title="Select Date Range" />
    <ag-row class="soa_main_wrapper">
      <ag-column xs="12" sm="12" md="12" lg="12" cols="12" v-if="isUserAgent">
        <MFinancialProfileCombobox
          class="fp-combo"
          v-model:inputValue="financialProfilePublicId"
          label="Financial Profiles"
          itemValue="value"
          itemLabel="label"
          :disabled="isFinancialProfileFetching"
          :options="financialProfileOptions"
        />
      </ag-column>
      <ag-column xs="12" sm="4" md="3" lg="2" cols="12">
        <ag-heading title="Sort By" variant="label"></ag-heading>
        <ag-select
          :items="items"
          v-model="sortBy"
          :testId="genTestId(ELEMENT_TYPES.SELECT_FIELD, 'generate-soa-sortBy')"
          :value="sortBy"
          variant="outlined"
        />
      </ag-column>
      <ag-column xs="12" sm="6" md="2" lg="2" cols="12">
        <ag-calendar
          label="Start Date"
          v-model="startDate"
          :testId="
            genTestId(ELEMENT_TYPES.CALENDAR_FIELD, 'generate-soa-startDate')
          "
          :date="startDate"
          @update:startDate="changeStartDate"
          calendar-name="startDate"
          :max-date="maxDate"
          :min-date="minStartDate"
        />
      </ag-column>
      <ag-column xs="12" sm="6" md="2" lg="2" cols="12">
        <ag-calendar
          label="End Date"
          :testId="
            genTestId(ELEMENT_TYPES.CALENDAR_FIELD, 'generate-soa-endDate')
          "
          v-model="endDate"
          :date="endDate"
          :min-date="startDate"
          @update:endDate="changeEndDate"
          calendar-name="endDate"
          :max-date="maxDate"
        />
      </ag-column>
      <ag-column
        xs="12"
        sm="6"
        md="5"
        lg="4"
        cols="12"
        class="d-flex align-items-center"
      >
        <MButton
          @click="onDownloadPDF"
          v-show="disableSendBtn"
          :disabled="disableButtons || $store.getters.isDownloadingSOA"
          :testId="genTestId(ELEMENT_TYPES.BUTTON, 'generate-soa-download-pdf')"
          class="margin_right_10"
        >
          Download PDF
        </MButton>
        <MButton
          v-show="isUserFinance"
          @click="onSendEmail"
          :disabled="disableButtons || $store.getters.isSendingSOA"
          :testId="genTestId(ELEMENT_TYPES.BUTTON, 'generate-soa-send-email')"
        >
          Send Email
        </MButton>
        <!-- <MButton
          v-show="!isUserAgent"
          @click="onDownloadOldSOA"
          :disabled="disableButtons || $store.getters.isSendingSOA"
          :testId="genTestId(ELEMENT_TYPES.BUTTON, 'generate-soa-send-email')"
        >
          Download Old SOA
        </MButton> -->
      </ag-column>
    </ag-row>
  </ag-card>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";

import { subDays } from "date-fns";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import analyticsService from "@/analytic.service";
import { SOA_ANALYTICS_COMMON_EVENTS } from "../constants";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { MFinancialProfileCombobox } from "@aeroglobe/ag-core-ui";
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";

enum SORT_BY {
  DATE = "Date",
  AIRLINE = "Airline",
}

export default defineComponent({
  name: "GenerateSOA",
  components: { MFinancialProfileCombobox },
  events: ["sendSOAEmail", "fetchSOA", "downloadOldSOA"],
  data() {
    return {
      ELEMENT_TYPES,
      items: [SORT_BY.DATE, SORT_BY.AIRLINE],
      sortBy: SORT_BY.DATE,
      financialProfilePublicId: "",
      startDate: new Date(),
      endDate: new Date(),
      maxDate: new Date(),
      minStartDate: subDays(
        new Date(),
        this.user()?.role_unique_id === USER_ROLES.AGENT ? 400 : 1000
      ),
      groupby: false,
    };
  },
  props: {
    disableActionBtns: {
      type: Boolean,
    },
    disableSendBtn: {
      type: Boolean,
    },
  },
  method: {
    disableButton() {
      return this.disableSendBtn;
    },
  },
  computed: {
    isUserFinance() {
      let user: IUserV2 | null = this.user();
      return user?.role_unique_id === USER_ROLES.FINANCE;
    },
    isUserAgent() {
      let user = (this.user() as IUserV2) || null;
      return user?.role_unique_id === USER_ROLES.AGENT;
    },
    disableButtons() {
      const isAgentFinancialProfilePublicIdAvailable =
        this.isUserAgent && this.financialProfilePublicId === "";

      if (
        this.disableActionBtns ||
        this.$store.getters.isSendingSOA ||
        this.$store.getters.isDownloadingSOA ||
        isAgentFinancialProfilePublicIdAvailable
      ) {
        return true;
      }
      return false;
    },
    financialProfileOptions(): FPComboboxOptions[] {
      let organization = this.organization() as IOrganizationFromLoginResponse;
      const financialProfiles =
        organization?.financial_profiles as IFinancialProfile[];

      if (financialProfiles) {
        const fpComboboxOptions = financialProfiles?.map(
          (fp: IFinancialProfile) => {
            const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase() as
              | "pro"
              | "elite"
              | "smart";
            const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
            const status: string = fp?.status;

            const fpComboboxOption = {
              id: fp?.platform_id,
              label: fp?.financial_profile_name,
              value: fp?.public_id,
              isActive: status === ORGANIZATION_STATUSES.ACTIVE,
              status: status,
              sector: sector ?? "",
              type: planType,
            };

            return fpComboboxOption;
          }
        );

        return fpComboboxOptions;
      } else {
        return [];
      }
    },
    isFinancialProfileFetching(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },
  },
  methods: {
    genTestId,
    changeStartDate(newDate: Date) {
      this.startDate = newDate;
    },
    onDownloadPDF() {
      this.$emit("fetchSOA", {
        startDate: this.startDate,
        endDate: this.endDate,
        sortBy: this.sortBy,
        financialProfilePublicId: this.financialProfilePublicId,
        sendEmail: false,
      });
      this.onTrackHandler(SOA_ANALYTICS_COMMON_EVENTS.SOA_DOWNLOAD);
    },
    onSendEmail() {
      if (this.disableSendBtn) {
        this.$emit("fetchSOA", {
          startDate: this.startDate,
          endDate: this.endDate,
          sortBy: this.sortBy,
          financialProfilePublicId: this.financialProfilePublicId,
          sendEmail: true,
        });
      } else {
        this.$emit("sendSOAEmail", {
          startDate: this.startDate,
          endDate: this.endDate,
          sortBy: this.sortBy,
          financialProfilePublicId: this.financialProfilePublicId,
          sendEmail: true,
        });
      }
      this.onTrackHandler(SOA_ANALYTICS_COMMON_EVENTS.SOA_SEND_EMAIL);
    },
    onDownloadOldSOA() {
      this.$emit("downloadOldSOA", {
        startDate: this.startDate,
        endDate: this.endDate,
        sortBy: this.sortBy,
        financialProfilePublicId: this.financialProfilePublicId,
        sendEmail: false,
      });

      this.onTrackHandler(SOA_ANALYTICS_COMMON_EVENTS.SOA_DOWNLOAD_OLD);
    },
    changeEndDate(newDate: Date) {
      this.endDate = newDate;
    },
    onTrackHandler(action: string) {
      let payload: any = {
        "financial-profile-id": this.financialProfilePublicId,
        "sort-by": this.sortBy,
        "start-date": this.startDate,
        "end-date": this.endDate,
      };

      analyticsService.logActionEvent(action, payload);
    },
  },
  mounted() {
    if (this.isUserAgent) {
      let organization = this.organization() as IOrganizationFromLoginResponse;
      let defaultFinancialProfile = organization.financial_profiles.find(
        (item) => item.is_default
      );

      if (defaultFinancialProfile) {
        this.financialProfilePublicId = defaultFinancialProfile.public_id;
      }
    }
  },
  setup() {
    let user = (inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2) || null;
    const organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse;
    return { user, organization };
  },
});
</script>
