import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37475768"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "width-150 text-align-center created-at-container" }
const _hoisted_3 = { class: "text-color-green" }
const _hoisted_4 = { class: "width-150 text-align-center" }
const _hoisted_5 = { class: "status-container width-150" }
const _hoisted_6 = { class: "width-165" }
const _hoisted_7 = { class: "action_btn_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_DocumentsModal = _resolveComponent("DocumentsModal")!
  const _component_ApproveOrganizationDialog = _resolveComponent("ApproveOrganizationDialog")!
  const _component_RejectOrganizationDialog = _resolveComponent("RejectOrganizationDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MDataTable, {
      headers: _ctx.headers,
      "is-loading": _ctx.isFetchingOrganizations,
      data: _ctx.organizationList || [],
      "item-per-page": 30,
      "has-search": true
    }, {
      name: _withCtx(({ item }) => [
        _createElementVNode("div", {
          class: "organization-link width-150",
          onClick: ($event: any) => (_ctx.handleRowClick($event, item))
        }, _toDisplayString(item.name), 9, _hoisted_1)
      ]),
      created_at: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, _toDisplayString(_ctx.formattedDate(item.created_at)), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formattedTime(item.created_at)), 1)
        ])
      ]),
      city: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(item?.city), 1)
      ]),
      status: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_MChip, {
            class: "log-type-item",
            borderLess: true,
            variant: _ctx.saasOrganizationStatusType(item?.status)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.status?.replaceAll("_", " ")), 1)
            ]),
            _: 2
          }, 1032, ["variant"])
        ])
      ]),
      documents: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_MButton, {
            disabled: _ctx.isViewDocumentsBtnDisabled(item.status),
            onClick: ($event: any) => (_ctx.handleClickViewDocuments(item.documents)),
            type: "filled"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" View Documents ")
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ])
      ]),
      action: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_MButton, {
            disabled: _ctx.isVerifyBtnDisabled(item.status),
            onClick: ($event: any) => (_ctx.handleOpenApproveDialog(item))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Approve ")
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"]),
          _createVNode(_component_MButton, {
            variant: "danger",
            disabled: _ctx.isRejectBtnDisabled(item.status),
            onClick: ($event: any) => (_ctx.handleOpenRejectDialog(item))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Reject ")
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ])
      ]),
      _: 1
    }, 8, ["headers", "is-loading", "data"]),
    _createVNode(_component_DocumentsModal, {
      "is-open": _ctx.isDocumentModalOpened,
      "selected-documents": _ctx.selectedDocuments,
      onCloseModal: _ctx.handleCloseDocumentModal
    }, null, 8, ["is-open", "selected-documents", "onCloseModal"]),
    _createVNode(_component_ApproveOrganizationDialog, {
      onHandleClose: _ctx.handleCloseDialog,
      onHandleAccept: _ctx.handleApprove,
      organization_name: _ctx.selectedOrganizationName,
      "is-open": _ctx.isAprroveDialogOpened
    }, null, 8, ["onHandleClose", "onHandleAccept", "organization_name", "is-open"]),
    _createVNode(_component_RejectOrganizationDialog, {
      onHandleClose: _ctx.handleCloseDialog,
      onHandleReject: _ctx.handleReject,
      organization_name: _ctx.selectedOrganizationName,
      "is-open": _ctx.isRejectDialogOpened
    }, null, 8, ["onHandleClose", "onHandleReject", "organization_name", "is-open"])
  ], 64))
}