import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_AgPhoneField = _resolveComponent("AgPhoneField")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_AGTextField = _resolveComponent("AGTextField")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    scrollable: "",
    "is-open": _ctx.isOpen,
    onClose: _ctx.closeModal,
    "modal-width": "40%"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode("Add Whatsapp Member")
        ]),
        _: 1
      }),
      _createVNode(_component_a_g_button, {
        type: "button",
        class: "modal_close_icon",
        variant: "link",
        onClick: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args))),
        class: "add-member-form"
      }, [
        _createVNode(_component_ag_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "12",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AgPhoneField, {
                  "test-id": "",
                  "default-country-code": "PK",
                  "info-message": "Please input the Whatsapp Member number\n            here",
                  error: _ctx.errors?.contact_number,
                  onUpdateValue: _ctx.handleMobileFieldChange
                }, null, 8, ["error", "onUpdateValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "12",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AGTextField, {
                  label: "Member Name",
                  modelValue: _ctx.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                  value: _ctx.name,
                  "test-id": "",
                  variant: "outlined",
                  error: _ctx.errors?.name
                }, null, 8, ["modelValue", "value", "error"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_MButton, {
          "test-id": "",
          behaviour: "submit",
          disabled: _ctx.$store.getters.isWhatsappActionLoading,
          type: "filled",
          style: {"float":"right"}
        }, {
          default: _withCtx(() => [
            _createTextVNode("Add")
          ]),
          _: 1
        }, 8, ["disabled"])
      ], 32)
    ]),
    _: 1
  }, 8, ["is-open", "onClose"]))
}