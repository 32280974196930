import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_ag_table = _resolveComponent("ag-table")!
  const _component_ag_not_found = _resolveComponent("ag-not-found")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_div, { class: "d-flex justify-content-between align-items-center" }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_heading, {
              variant: "h2",
              class: "margin_bottom_0",
              title: "Agent Credit Limit Request"
            }),
            _createVNode(_component_ag_div, { class: "credit_limit_btn_space" }, {
              default: _withCtx(() => [
                _createVNode(_component_MButton, {
                  "test-id": _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'create-credit-limit'),
                  onClick: _ctx.routeToCreateCreditLimitRequest
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Request Limit ")
                  ]),
                  _: 1
                }, 8, ["test-id", "onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_div, null, {
          default: _withCtx(() => [
            (_ctx.isFetchingCreditLimitRequests)
              ? (_openBlock(), _createBlock(_component_ag_loader, {
                  key: 0,
                  class: "padding_top_20"
                }))
              : (
          !_ctx.isFetchingCreditLimitRequests && _ctx.creditLimitRequests.data.length
        )
                ? (_openBlock(), _createBlock(_component_ag_table, {
                    key: 1,
                    headers: _ctx.headers,
                    items: _ctx.creditLimitRequests.data,
                    "total-page-count": _ctx.creditLimitRequests.totalPages,
                    page: _ctx.page,
                    onOnPageChange: _ctx.onPageChange,
                    "items-per-page": _ctx.itemsPerPage,
                    "has-pagination": true,
                    "has-search": false
                  }, {
                    "col-requestInitiatedAt": _withCtx(({ item }) => [
                      (item.raw.requestInitiatedAt)
                        ? (_openBlock(), _createBlock(_component_ag_heading, {
                            key: 0,
                            title: 
              _ctx.getFormattedDateTime(
                item.raw.requestInitiatedAt,
                _ctx.FORMAT_YYY_MM_DD
              )
            ,
                            class: "margin_bottom_0 margin_top_20",
                            variant: "p"
                          }, null, 8, ["title"]))
                        : _createCommentVNode("", true),
                      (item.raw.requestInitiatedAt)
                        ? (_openBlock(), _createBlock(_component_ag_heading, {
                            key: 1,
                            class: "text-color-green",
                            title: 
              _ctx.getFormattedDateTime(item.raw.requestInitiatedAt, _ctx.FORMAT_HH_SS_A)
            ,
                            variant: "p"
                          }, null, 8, ["title"]))
                        : _createCommentVNode("", true),
                      (!item.raw.requestInitiatedAt)
                        ? (_openBlock(), _createBlock(_component_ag_heading, {
                            key: 2,
                            title: "N/A",
                            variant: "p"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    "col-requestedAmount": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatNumber(item.raw.requestedAmount)), 1)
                    ]),
                    "col-limitExpiresAt": _withCtx(({ item }) => [
                      (item.raw.requestInitiatedAt)
                        ? (_openBlock(), _createBlock(_component_ag_heading, {
                            key: 0,
                            title: 
              _ctx.getFormattedDateTime(item.raw.limitExpiresAt, _ctx.FORMAT_YYY_MM_DD)
            ,
                            class: "margin_bottom_0 margin_top_20",
                            variant: "p"
                          }, null, 8, ["title"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ag_heading, {
                        class: "text-color-green",
                        title: 
              _ctx.getFormattedDateTime(item.raw.limitExpiresAt, _ctx.FORMAT_HH_SS_A)
            ,
                        variant: "p"
                      }, null, 8, ["title"])
                    ]),
                    "col-status": _withCtx(({ item }) => [
                      _createVNode(_component_ag_div, {
                        class: _normalizeClass(_ctx.creditLimitStatusClass(item.raw.status))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.raw.status), 1)
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ]),
                    "col-priority": _withCtx(({ item }) => [
                      _createVNode(_component_ag_div, {
                        class: _normalizeClass(_ctx.creditLimitPriorityclass(item.raw.priority))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.raw.priority), 1)
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ]),
                    _: 1
                  }, 8, ["headers", "items", "total-page-count", "page", "onOnPageChange", "items-per-page"]))
                : (_openBlock(), _createBlock(_component_ag_not_found, {
                    key: 2,
                    heading: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND,
                    description: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND_DESCRIPTION
                  }, null, 8, ["heading", "description"]))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}