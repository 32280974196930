import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_radio_item = _resolveComponent("ag-radio-item")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_AgTable = _resolveComponent("AgTable")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_ag_radio = _resolveComponent("ag-radio")!
  const _component_a_g_text_field = _resolveComponent("a-g-text-field")!
  const _component_financial_profile_dropdown = _resolveComponent("financial-profile-dropdown")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_Agdiv = _resolveComponent("Agdiv")!

  return (_ctx.isPaymentMethodLoaded)
    ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
    : (_openBlock(), _createBlock(_component_ag_card, { key: 1 }, {
        default: _withCtx(() => [
          (!_ctx.isAnyPaymentMethod)
            ? (_openBlock(), _createBlock(_component_AgNotFound, {
                key: 0,
                "test-id": "",
                heading: "No Payment Methods Found",
                description: ""
              }))
            : (_openBlock(), _createBlock(_component_Agdiv, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_heading, {
                    title: "Create New Payment",
                    variant: "h3"
                  }),
                  _createVNode(_component_ag_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_heading, {
                            title: "Select Payment Method",
                            variant: "label"
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.PaymentMethods, (type) => {
                            return (_openBlock(), _createBlock(_component_ag_radio, {
                              key: type.payment_type,
                              modelValue: _ctx.method,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.method) = $event)),
                              testId: 
              _ctx.genTestId(_ctx.ELEMENT_TYPES.RADIO_BUTTON, 'create-payment-method')
            
                            }, {
                              default: _withCtx(() => [
                                (type.payment_type === 'IBFT' && type.is_active === true)
                                  ? (_openBlock(), _createBlock(_component_ag_card, { key: 0 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ag_radio_item, {
                                          testId: 
                  _ctx.genTestId(_ctx.ELEMENT_TYPES.RADIO_BUTTON, 'create-payment-IBFT')
                ,
                                          value: _ctx.PAYMENT_METHODS_TYPES.IBFT,
                                          name: "method",
                                          label: "IBFT - Bank Transfer"
                                        }, null, 8, ["testId", "value"]),
                                        (_ctx.bankDetails?.bank_account_no !== null && _ctx.isBank)
                                          ? (_openBlock(), _createBlock(_component_AgTable, {
                                              key: 0,
                                              headers: _ctx.bankTable,
                                              items: [_ctx.bankDetails],
                                              "has-pagination": false,
                                              "items-per-page": 1,
                                              "has-search": false
                                            }, {
                                              "col-bank_name": _withCtx(({ item }) => [
                                                _createVNode(_component_ag_div, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(item.raw.bank_name), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              "col-bank_iban": _withCtx(({ item }) => [
                                                _createVNode(_component_ag_div, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(item.raw.bank_iban), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              "col-bank_title": _withCtx(({ item }) => [
                                                _createVNode(_component_ag_div, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(item.raw.bank_title), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              "col-bank_account_no": _withCtx(({ item }) => [
                                                _createVNode(_component_ag_div, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(item.raw.bank_account_no), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 1
                                            }, 8, ["headers", "items"]))
                                          : _createCommentVNode("", true),
                                        (_ctx.isBank)
                                          ? (_openBlock(), _createBlock(_component_ag_div, {
                                              key: 1,
                                              class: "margin_top_20"
                                            }, {
                                              default: _withCtx(() => [
                                                (_ctx.bankDetails?.bank_account_no !== null)
                                                  ? (_openBlock(), _createBlock(_component_ag_heading, {
                                                      key: 0,
                                                      title: `Note: ${_ctx.noteForAmount}`,
                                                      variant: "b"
                                                    }, null, 8, ["title"]))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        (_ctx.method === _ctx.PAYMENT_METHODS_TYPES.IBFT)
                                          ? (_openBlock(), _createBlock(_component_ag_heading, {
                                              key: 2,
                                              title: `${_ctx.noteForPayment(
                  type.payment_fee_value || 0,
                  type.payment_type,
                  type.payment_fee_type,
                  type.currency
                )}`,
                                              variant: "b"
                                            }, null, 8, ["title"]))
                                          : _createCommentVNode("", true),
                                        (_ctx.bankDetails?.bank_account_no === null && _ctx.isBank)
                                          ? (_openBlock(), _createBlock(_component_AgNotFound, {
                                              key: 3,
                                              "test-id": "",
                                              heading: "Please contact billing team to have your IBFT details updated",
                                              description: ""
                                            }))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                (type.payment_type === 'ONE_BILL' && type.is_active === true)
                                  ? (_openBlock(), _createBlock(_component_ag_card, { key: 1 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ag_radio_item, {
                                          testId: 
                  _ctx.genTestId(
                    _ctx.ELEMENT_TYPES.RADIO_BUTTON,
                    'create-payment-ONE_BILL'
                  )
                ,
                                          value: _ctx.PAYMENT_METHODS_TYPES.ONE_BILL,
                                          name: "method",
                                          label: "One Bill"
                                        }, null, 8, ["testId", "value"]),
                                        (_ctx.method === _ctx.PAYMENT_METHODS_TYPES.ONE_BILL)
                                          ? (_openBlock(), _createBlock(_component_ag_heading, {
                                              key: 0,
                                              title: `${_ctx.noteForPayment(
                  type.payment_fee_value || 0,
                  type.payment_type,
                  type.payment_fee_type,
                  type.currency
                )}`,
                                              variant: "b"
                                            }, null, 8, ["title"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                (
                type.payment_type === 'CREDIT_CARD' && type.is_active === true
              )
                                  ? (_openBlock(), _createBlock(_component_ag_card, { key: 2 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ag_radio_item, {
                                          testId: 
                  _ctx.genTestId(
                    _ctx.ELEMENT_TYPES.RADIO_BUTTON,
                    'create-payment-CREDIT_CARD'
                  )
                ,
                                          value: _ctx.PAYMENT_METHODS_TYPES.CREDIT_CARD,
                                          name: "method",
                                          label: "Debit/Credit Card"
                                        }, null, 8, ["testId", "value"]),
                                        (_ctx.method === _ctx.PAYMENT_METHODS_TYPES.CREDIT_CARD)
                                          ? (_openBlock(), _createBlock(_component_ag_heading, {
                                              key: 0,
                                              title: `${_ctx.noteForPayment(
                  type.payment_fee_value || 0,
                  type.payment_type,
                  type.payment_fee_type,
                  type.currency
                )}`,
                                              variant: "b"
                                            }, null, 8, ["title"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "testId"]))
                          }), 128)),
                          (_ctx.errors?.method)
                            ? (_openBlock(), _createBlock(_component_ag_heading, {
                                key: 0,
                                class: "color-red",
                                title: _ctx.errors?.method,
                                variant: "p"
                              }, null, 8, ["title"]))
                            : _createCommentVNode("", true),
                          (!_ctx.isBank)
                            ? (_openBlock(), _createElementBlock("form", {
                                key: 1,
                                onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
                              }, [
                                (!_ctx.isBank)
                                  ? (_openBlock(), _createBlock(_component_ag_heading, {
                                      key: 0,
                                      title: "Amount",
                                      variant: "label"
                                    }))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_a_g_text_field, {
                                  testId: 
                _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'create-payment-amount')
              ,
                                  error: _ctx.errors?.amount,
                                  modelValue: _ctx.amount,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.amount) = $event)),
                                  type: "number",
                                  value: _ctx.amount
                                }, null, 8, ["testId", "error", "modelValue", "value"]),
                                (_ctx.amount && !_ctx.isBank)
                                  ? (_openBlock(), _createBlock(_component_ag_heading, {
                                      key: 1,
                                      title: `Note: ${_ctx.paymentAmount()}`,
                                      variant: "b"
                                    }, null, 8, ["title"]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_financial_profile_dropdown, {
                                  class: "margin_top_20",
                                  label: "Financial Profile",
                                  error: _ctx.errors['financial_profile_public_id'],
                                  onOnUpdateFinancialProfile: _ctx.updateFinancialProfilePublicId
                                }, null, 8, ["error", "onOnUpdateFinancialProfile"]),
                                _createVNode(_component_ag_heading, {
                                  title: "Description",
                                  variant: "label"
                                }),
                                _createVNode(_component_a_g_text_field, {
                                  testId: 
                _ctx.genTestId(
                  _ctx.ELEMENT_TYPES.TEXT_FIELD,
                  'create-payment-description'
                )
              ,
                                  modelValue: _ctx.description,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.description) = $event)),
                                  value: _ctx.description
                                }, null, 8, ["testId", "modelValue", "value"]),
                                _createVNode(_component_ag_div, { class: "d-flex justify-content-end" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_MButton, {
                                      variant: "filled",
                                      class: "margin_right_20",
                                      onClick: _ctx.routeToPaymentList,
                                      disabled: _ctx.$store.getters.isCreatingPayment
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Cancel ")
                                      ]),
                                      _: 1
                                    }, 8, ["onClick", "disabled"]),
                                    _createVNode(_component_MButton, {
                                      type: "filled",
                                      testId: 
                  _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'create-payment-submit')
                ,
                                      disabled: _ctx.$store.getters.isCreatingPayment,
                                      "is-loading": _ctx.$store.getters.isCreatingPayment,
                                      behaviour: "submit"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Create Payment ")
                                      ]),
                                      _: 1
                                    }, 8, ["testId", "disabled", "is-loading"])
                                  ]),
                                  _: 1
                                })
                              ], 32))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      }))
}