import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ffd517c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "btn-container"
}
const _hoisted_2 = { class: "chip-cell-container" }
const _hoisted_3 = { class: "icon-cell-container" }
const _hoisted_4 = { class: "icon-cell-container" }
const _hoisted_5 = { class: "icon-cell-container" }
const _hoisted_6 = { class: "delete-btn-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_RoundCheckCrossIcon = _resolveComponent("RoundCheckCrossIcon")!
  const _component_MFabButton = _resolveComponent("MFabButton")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_AddAgentModal = _resolveComponent("AddAgentModal")!
  const _component_AgentDeleteConfirmationModal = _resolveComponent("AgentDeleteConfirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showAddNewAgentBtn())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.hasAddNewAgent)
            ? (_openBlock(), _createBlock(_component_MButton, {
                key: 0,
                variant: "filled",
                onClick: _ctx.onAddNewAgentBtnClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Add New Agent")
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_MDataTable, {
      "has-pagination": _ctx.hasPagination,
      "has-search": _ctx.hasSearch,
      headers: _ctx.headers,
      data: _ctx.agents
    }, {
      type: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_MChip, {
            class: "type-chip",
            "border-less": true,
            variant: _ctx.renderTypeVariant(item?.type) || 'default'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.type), 1)
            ]),
            _: 2
          }, 1032, ["variant"])
        ])
      ]),
      can_login: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_RoundCheckCrossIcon, {
            "is-check": item.can_login
          }, null, 8, ["is-check"])
        ])
      ]),
      can_issue_flight: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_RoundCheckCrossIcon, {
            "is-check": item.can_issue_flight
          }, null, 8, ["is-check"])
        ])
      ]),
      can_issue_hotel: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_RoundCheckCrossIcon, {
            "is-check": item.can_issue_hotel
          }, null, 8, ["is-check"])
        ])
      ]),
      action: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.isEnableUserDeletion(item?.type))
            ? (_openBlock(), _createBlock(_component_MFabButton, {
                key: 0,
                onClick: ($event: any) => (_ctx.onDeleteAgentRequest(item?.agent)),
                class: "delete-icon",
                icon: "m-delete"
              }, null, 8, ["onClick"]))
            : (_openBlock(), _createBlock(_component_MFabButton, {
                key: 1,
                class: "delete-icon",
                disabled: true,
                icon: "m-delete"
              }))
        ])
      ]),
      _: 1
    }, 8, ["has-pagination", "has-search", "headers", "data"]),
    _createVNode(_component_AddAgentModal, {
      "is-open": _ctx.isAddAgentDialogOpen,
      onHandleClose: _ctx.onCloseAddAgentDialog,
      onOnSubmit: _ctx.onAddEmail
    }, null, 8, ["is-open", "onHandleClose", "onOnSubmit"]),
    _createVNode(_component_AgentDeleteConfirmationModal, {
      "is-open": _ctx.isDeleteAgentDialogOpen,
      agent: _ctx.selectedAgent,
      onOnCloseModal: _ctx.closeAgentDeletionModal,
      onOnDeleteAgent: _ctx.deleteAgent
    }, null, 8, ["is-open", "agent", "onOnCloseModal", "onOnDeleteAgent"])
  ], 64))
}